<template>
  <div class="home">
    <el-carousel :interval="8000" height="464px" class="home_card">
      <el-carousel-item v-for="item in imgList" :key="item + 'img'">
        <div :style="'background: url(' + item + ') no-repeat center center;'" class="home_card_item"></div>
      </el-carousel-item>
    </el-carousel>
    <div class="home_intr">
      <div class="home_intr_left">
        <div class="home_intr_left_top">
          <div class="home_intr_left_top_content">
            紧跟国家战略导向 实现产业融合创新
            务实解决民之所需&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;金融孵化实体
            科技赋能生态 梦想支撑品牌 专业呈现未来
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            紧跟国家战略导向 实现产业融合创新
            务实解决民之所需&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;金融孵化实体
            科技赋能生态 梦想支撑品牌 专业呈现未来
          </div>
          <div class="home_intr_left_top_content">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            紧跟国家战略导向 实现产业融合创新
            务实解决民之所需&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;金融孵化实体
            科技赋能生态 梦想支撑品牌 专业呈现未来
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            紧跟国家战略导向 实现产业融合创新
            务实解决民之所需&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;金融孵化实体
            科技赋能生态 梦想支撑品牌 专业呈现未来
          </div>
        </div>
        <div class="home_intr_left_bottom">
          <div class="home_intr_left_bottom_content">
            <!-- 金融孵化实体 科技赋能生态 梦想支撑品牌 专业呈现未来 -->
          </div>
        </div>
      </div>
    </div>
    <div class="home_news">
      <div class="home_news_bg">
        <el-carousel height="380px" indicator-position="none" ref="newsImgCarousel" arrow="never"
          @change="changeNewsImgCarousel" class="newsImgCarousel">
          <el-carousel-item v-for="(item, index) in newsList" :key="item.newsId + 'newsImg'">
            <img src="../../static/newImage/img_loading.png" class="img_loading" v-show="imgSuffix" />
            <img :src="item.newsCover + imgSuffix" v-show="index == 0" width="586px" @click="thereJunp(item, 22)" />
            <img :src="item.newsCover + imgSuffix" v-show="index == 1" width="586px" @click="thereJunp(item, 22)" />
            <img :src="item.newsCover + imgSuffix" v-show="index == 2" width="586px" @click="thereJunp(item, 22)" />
          </el-carousel-item>
        </el-carousel>
        <div class="home_news_bg_bottom">
          <div class="home_news_bg_bottom_title" v-if="newsList.length"
            @click="thereJunp(newsList[newsImageIndex], 22)">
            {{ newsList[newsImageIndex].newsTitle }}</div>
          <div>
            <div :class="newsImageIndex == index
              ? 'home_news_bg_bottom_Index active'
              : 'home_news_bg_bottom_Index'
              " v-for="(i, index) in newsList" @click="changeNewsImageIndex(index)" :key="index + 'newsImg1'">
              {{ index + 1 }}
            </div>
          </div>
        </div>
      </div>
      <div class="home_news_left">
        <div class="home_news_left_title">
          <div :class="newsIndex == 22
            ? 'home_news_left_title_name active'
            : 'home_news_left_title_name'
            " @mouseenter="changeNewsIndex(22)">
            集团新闻
          </div>
          <!-- <div :class="newsIndex == 23
            ? 'home_news_left_title_name active'
            : 'home_news_left_title_name'
            " @mouseenter="changeNewsIndex(23)">
            行业资讯
          </div>
          <div :class="newsIndex == 24
            ? 'home_news_left_title_name active'
            : 'home_news_left_title_name'
            " @mouseenter="changeNewsIndex(24)">
            热点专题
          </div> -->
        </div>
        <div class="home_news_left_list moveTop" @click="thereJunp(item, 22)" v-for="item in newsList"
          :key="'newsList' + item.newsId">
          <div class="home_news_left_list_title">{{ item.newsTitle }}</div>
          <div class="home_news_left_list_day">{{ item.publishTime }}</div>
        </div>
      </div>
    </div>
    <!-- 第一个 -->
    <div class="home_all">
      <div class="home_all_module">
        <div class="home_all_module_name">
          <div>集团要闻</div>
          <div class="home_all_module_name_more" @click="toPath('/press_center', 17)">
            >>更多
          </div>
        </div>
        <div class="home_all_module_name">
          <div>产业动态</div>
          <div class="home_all_module_name_more" @click="toPath('/press_center', 16)">
            >>更多
          </div>
        </div>
        <div class="home_all_module_name">
          <div>媒体报道</div>
          <div class="home_all_module_name_more" @click="toPath('/press_center', 15)">
            >>更多
          </div>
        </div>
      </div>
      <div class="home_all_module">
        <div class="swiper-container swiper-container1">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in img_list1" :key="item.id"
              style="width: 400px;height:163px;overflow: hidden;margin-top: 20px !important;">
              <img src="../../static/newImage/img_loading.png" class="img_loading" v-show="imgSuffix" />
              <img class="home_all_module_img" :src="item.newsCover + imgSuffix" style="width: 100%;"
                @click="thereJunp(item, 17)" />
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination1"></div>
        </div>
        <div class="swiper-container swiper-container2">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in img_list2" :key="item.id"
              style="width: 400px;height:163px;overflow: hidden;margin-top: 20px !important;">
              <img src="../../static/newImage/img_loading.png" class="img_loading" v-show="imgSuffix" />
              <img class="home_all_module_img" :src="item.newsCover + imgSuffix" style="width: 100%;"
                @click="thereJunp(item, 16)" />
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination2"></div>
        </div>
        <div class="swiper-container swiper-container3">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in img_list3" :key="item.id"
              style="width: 400px;height:163px;overflow: hidden;margin-top: 20px !important;">
              <img src="../../static/newImage/img_loading.png" class="img_loading" v-show="imgSuffix" />
              <img class="home_all_module_img" :src="item.newsCover + imgSuffix" style="width: 100%;"
                @click="thereJunp(item, 15)" />
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination3"></div>
        </div>
      </div>
      <div class="home_all_module">
        <div class="home_all_content" style="width: 32%; margin-right: 2%">
          <div class="home_all_content_item moveTop" @click="thereJunp(item, 17)" v-for="item in img_list1"
            :key="item.newsId">
            {{ item.newsTitle }}
          </div>
        </div>
        <div class="home_all_content" style="width: 32%; margin-right: 2%">
          <div class="home_all_content_item moveTop" @click="thereJunp(item, 16)" v-for="item in img_list2"
            :key="item.newsId">
            {{ item.newsTitle }}
          </div>
        </div>
        <div class="home_all_content" style="width: 32%">
          <div class="home_all_content_item moveTop" @click="thereJunp(item, 15)" v-for="item in img_list3"
            :key="item.newsId">
            {{ item.newsTitle }}
          </div>
        </div>
      </div>
    </div>
    <!-- 第二个 -->
    <div class="home_all">
      <div class="home_all_module">
        <div class="home_all_module_name">
          <div>政府支持</div>
          <div class="home_all_module_name_more" @click="toPath('/press_center', 27)">
            >>更多
          </div>
        </div>
        <div class="home_all_module_name">
          <div>社会贡献</div>
          <div class="home_all_module_name_more" @click="toPath('/press_center', 28)">
            >>更多
          </div>
        </div>
        <div class="home_all_module_name">
          <div>热点专题</div>
          <div class="home_all_module_name_more" @click="toPath('/press_center', 24)">
            >>更多
          </div>
        </div>
      </div>
      <!-- TODO -->
      <div class="home_all_module">
        <div class="swiper-container swiper-container4">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in img_list4" :key="item.id"
              style="width: 400px;height:163px;overflow: hidden;margin-top: 20px !important;">
              <img src="../../static/newImage/img_loading.png" class="img_loading" v-show="imgSuffix" />
              <img class="home_all_module_img" :src="item.newsCover + imgSuffix" style="width: 100%;"
                @click="thereJunp(item, 27)" />
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination4"></div>
        </div>
        <div class="swiper-container swiper-container5">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in img_list5" :key="item.id"
              style="width: 400px;height:163px;overflow: hidden;margin-top: 20px !important;">
              <img src="../../static/newImage/img_loading.png" class="img_loading" v-show="imgSuffix" />
              <img class="home_all_module_img" :src="item.newsCover + imgSuffix" style="width: 100%;"
                @click="thereJunp(item, 28)" />
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination5"></div>
        </div>
        <div class="swiper-container swiper-container6">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in img_list6" :key="item.id"
              style="width: 400px;height:163px;overflow: hidden;margin-top: 20px !important;">
              <img src="../../static/newImage/img_loading.png" class="img_loading" v-show="imgSuffix" />
              <img class="home_all_module_img" :src="item.newsCover + imgSuffix" style="width: 100%;"
                @click="thereJunp(item, 24)" />
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination6"></div>
        </div>
      </div>
      <div class="home_all_module">
        <div class="home_all_content" style="width: 32%; margin-right: 2%">
          <div class="home_all_content_item moveTop" @click="thereJunp(item, 27)" v-for="item in img_list4"
            :key="item.newsId">
            {{ item.newsTitle }}
          </div>
        </div>
        <div class="home_all_content" style="width: 32%; margin-right: 2%">
          <div class="home_all_content_item moveTop" @click="thereJunp(item, 28)" v-for="item in img_list5"
            :key="item.newsId">
            {{ item.newsTitle }}
          </div>
        </div>
        <div class="home_all_content" style="width: 32%">
          <div class="home_all_content_item moveTop" @click="thereJunp(item, 24)" v-for="item in img_list6"
            :key="item.newsId">
            {{ item.newsTitle }}
          </div>
        </div>
      </div>
    </div>
    <div class="home_img">
      <img src="../../static/newImage/home9.jpg" width="100%" />
    </div>
    <div class="home_ten">
      <div class="home_box home_all">
        <div class="box">
          <div class="boxImg">
            <!-- <img src="../../static/newImage/chanye3.png" width="1240px" /> -->
            <!-- 第一版 -->
            <div class="centre" v-if="container7">
              <img style="
                  width: 661px;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                " src="../../static/newImage/chanye1.png" alt="" />
              <div class="all-btn" style="margin-top: 283px; margin-bottom: 25px">
                <div class="l-btn" style="margin-left: 90px" @click="toHomePath(1, { id: 1 })"
                  :class="{ centreSty: container7.activeIndex == 12 }">
                  养老板块
                </div>
                <div class="l-btn" style="margin-right: 90px" @click="toHomePath(10, { id: 9 })"
                  :class="{ centreSty: container7.activeIndex == 11 || container7.activeIndex == 1 }">
                  制药板块
                </div>
              </div>
              <div class="all-btn" style="margin-bottom: 25px">
                <div class="l-btn" @click="toHomePath(2, { id: 5 })"
                  :class="{ centreSty: container7.activeIndex == 13 || container7.activeIndex == 3 }">中峪商贸</div>
                <div class="l-btn" @click="toHomePath(9, { id: 8 })"
                  :class="{ centreSty: container7.activeIndex == 10 || container7.activeIndex == 0 }">医院板块</div>
              </div>
              <div class="all-btn" style="margin-bottom: 25px">
                <div class="l-btn" style="margin-left: 60px" @click="toHomePath(2, { id: 3 })"
                  :class="{ centreSty: container7.activeIndex == 4 }">
                  中峪学校
                </div>
                <div class="l-btn" style="margin-right: 60px" @click="toHomePath(8, { id: 4 })"
                  :class="{ centreSty: container7.activeIndex == 9 }">
                  金生投资
                </div>
              </div>
              <div class="all-btn" style="margin-bottom: 25px">
                <div class="l-btn" style="margin-left: 230px" @click="toHomePath(3, { id: 7 })"
                  :class="{ centreSty: container7.activeIndex == 5 }">
                  麒典信息科技
                </div>
                <div class="l-btn" style="margin-right: 230px" @click="toHomePath(7, { id: 1 })"
                  :class="{ centreSty: container7.activeIndex == 8 }">
                  中峪交易市场
                </div>
              </div>
              <div class="all-btn">
                <div class="l-btn" style="margin-left: 350px" @click="toHomePath(4, { id: 6 })"
                  :class="{ centreSty: container7.activeIndex == 6 }">
                  道地药材
                </div>
                <div class="l-btn" style="margin-right: 350px" @click="toHomePath(5, { id: 2 })"
                  :class="{ centreSty: container7.activeIndex == 7 }">
                  中峪数交
                </div>
              </div>
            </div>
            <div class="home_all_item" id="scrollMedicine">
              <div class="swiper-container swiper-container7">
                <div class="swiper-wrapper">
                  <div class="swiper-slide home_all_item_index" v-for="(item, index) in Industry_category"
                    :key="item.id + 'img222'" @click="toHomePath(index, item)" :data-index1="index">
                    <div class="home_all_module">
                      <img class="home_all_module_img" :src="item.img" style="height: 400px;" />
                    </div>
                    <div class="home_all_module home_all_bg">
                      <div class="home_all_module_black">
                        <div class="home_all_module_black_intro active">
                          <div class="home_all_module_black_intro_title">
                            {{ item.text }}
                            <div class="home_all_module_black_intro_title_red"></div>
                          </div>
                          <div class="home_all_module_black_intro_content">
                            {{ item.content }}
                          </div>
                          <div class="home_all_module_black_intro_more">
                            查看更多 >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="home_all_module">
                      <div class="home_all_content">
                        <div class="home_all_content_item moveTop" v-for="item1 in img_list1"
                          @click="thereJunp(item1, 17)" :key="item1.id">
                          {{ item1.newsTitle }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 第一版 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Pagination, Autoplay } from "swiper";
import axios from "axios";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "Home",
  data() {
    return {
      imgSuffixTime: null,
      imgSuffix: '?x-oss-process=image/resize,m_lfit,w_300',
      imgList: [
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/da2d8efc6c5e43b2be0f5d688e91748f.gif",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-06/0d681aca647549eaa3b85c005f9f7c46.gif",
        require("../../static/newImage/banner2.jpg"),
        require("../../static/newImage/banner.jpg"),
        require("../../static/newImage/banner4.jpg"),
      ],
      newsList: [],
      newsImgList: [
        require("../../static/newImage/home1.jpg"),
        require("../../static/newImage/home2.jpg"),
        require("../../static/newImage/home3.jpg"),
      ],
      newsIndex: 22,
      newsImageIndex: 0,
      animationFont:
        "立民族志气，创世界品牌|企业的发展，一切以国家政策为导向|不忘初心，牢记使命，为社会创造价值|继承发扬中医文化，为祖国中医药事业发展做贡献|守护道地药材，发掘祖传秘方，传承中医国粹，提升国民生活品质|振兴乡村经济，促进市场经济循环，提供创造就业岗位，助力地方产业融合发展科技融入生命，专业服务生活，让养老从心定义！|助力国家就业体系，造就社会专业人才",
      fontInterval: null,
      fontTimeout: null,
      Industry_category: [
        {
          id: "10",
          text: "养老板块",
          content: "板块正在建设中……",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/30c0338582b04652be40f3cad042c7df养老w.jpg",
        },
        {
          id: "5",
          text: "中峪商贸",
          content:
            "中峪商贸为四川省泸州市地方政府重点招商引资项目，是一家承载孵化落地集团全产业链使命的运营公司，以其独特商业运营架构和科学创新的市场培育体系，助推地方中小企业产品流通，促进地方区域经济发展，优化商品流通渠道。协同中峪拍交所为集团产业导入高粘度市场流量，协同中峪职业培训学校共同孵化输送业务体系人才，为地方解决就业、创业而努力。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/f3ca51e6682a40b284ae67b1cf0f9c6a贸易.jpg",
        },
        {
          id: "3",
          text: "中峪学校",
          content:
            "中峪职业培训学校是一家结合国家职业教育体系改革指导政策，肩负起特色职业技能培育，为中峪产业培育人才，给社会输送专业人才，同时解决就业再就业问题，为地方职业教育发展提供助力，为养老和文化产业发展提供助力。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/d06c185094f14e479e4731fc7280ff4c学校.jpg",
        },
        {
          id: "7",
          text: "麒典信息科技",
          content:
            "麒典信息科技是一家集大数据服务、核心技术研发、技术咨询服务、区块链技术应用、软件开发等科技研发公司，为集团产业链数字化运作提供强大技术输出，肩负集团产业链大数据信息储存输送和科技数字化运作重任，同时为助力地方产业数字化发展提供强大技术支撑，承载银发经济链产品研发和制造。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/6680f9a48b704f02a86e7a071a94ccd0信息科技.jpg",
        },
        {
          id: "6",
          text: "道地药材",
          content:
            "浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工、仓储物流等，推动地方文旅、科研、产教研学、人才培养等多元化产业发展；同时积极探索药、风、光、零碳、能源产业带的同步推进。在集团内循环产业链赋能下，跨界融合部署，遵循国家政策，紧跟国家战略，以“数字经济新农业 乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。",
          img: require("../../static/newImage/home10.jpg"),
        },
        {
          id: "2",
          text: "中峪数交",
          content:
            "四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+数字拍卖服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为实现数字经济新农业，乡村振兴新载体发展起到强大支撑和赋能，同时发挥资产数字化拍卖平台的优势，为实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/c1ccf2fa3f9e4fa68737ff319e89020d拍卖.jpg",
        },
        {
          id: "1",
          text: "中峪交易市场",
          content:
            "中峪日用品交易市场均为中峪集团十大闭环产业之一，具有多行业背景和资源优势，整合融通了多品种商品资源、金融服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，帮助中小企业实现去库存、优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+金融服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为中峪道地药材产业链发展建立资产数字化交易平台，为实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/a9e5bb46974d4d34916934526c623ed3商务配图.jpg",
        },
        {
          id: "4",
          text: "金生投资",
          content:
            "投资管理公司不仅仅为各产业提供金融助力，更是为了集团进一步发展服务，在集团稳健发展的基础上，进行分层级的资本良性运作，为社会创造更多的财富，为共同富裕助力。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/f946dd953423416fa472ff1ede2de034投2资.jpg",
        },
        {
          id: "8",
          text: "医院板块",
          content: "板块正在建设中……",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/ca8e0b6b57b342e7a253c538e45f929d医院.jpg",
        },
        {
          id: "9",
          text: "制药板块",
          content:
            "生物制药坚持中医药原创思维，深入发掘中医药精华，是一家传承古代经典名方、名老中医经验方、传统民族药业、新药研发为一体的高新技术制药研发公司。为传承中医药文化，促进中医药特色发展，提升中医药产业水平做出积极贡献，是中峪道地药材的制药基地，是中峪医院的药材基地，为中峪康养产业提供优质药食同源。",
          img: "http://bizpub.oss-cn-hangzhou.aliyuncs.com/d73dee92df23467c94003b5604c98947制药.jpg",
        },
      ],
      img_list1: [],
      img_list2: [],
      img_list3: [],
      img_list4: [],
      img_list5: [],
      img_list6: [],
      device: false,
      changecyIndex: 0,
      pxValue: 0,
      swiperImg1: null,
      swiperImg2: null,
      swiperImg3: null,
      swiperImg4: null,
      swiperImg5: null,
      swiperImg6: null,
      container7: null,
      liudongInterval: null
    };
  },
  mounted() {
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = ''
    }, 4000)
    // imgList
    const reg = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (reg.test(navigator.userAgent)) {
      this.imgList.splice(0, 1)
    } else {
      this.imgList.splice(1, 1)
    }
    if (this.$refs.newsImgCarousel) {
      this.slideBanner()
    }
    Swiper.use([Pagination, Autoplay])
    this.getNewsList();
    this.changeNewsIndex(22)
    this.getWXInfo()
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: '中峪集团', // 分享标题
            desc: '浙江中峪控股集团有限公司是一家涉足商贸、拍卖交易所、职业培训学校、中医大健康、医疗、生物制药、网络科技、养老产业、金融等十大闭环产业的集团化公司，集团以国家政策为导向，以大众创业，万众创新为初心，跨界融合、科技创新、数字经济、跨产业赋能、多元化经营、服务于各生产企业的同时，为市场培育孵化更多优质的人才', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/68e0428cfa004aa08b92bb333a0c2d94.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector('.newsImgCarousel .el-carousel__container');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function () {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.newsImgCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.newsImgCarousel.prev();
          return;
        }
      });
    },
    changeNewsIndex(index) {
      this.newsIndex = index;
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=3&websiteCategoryId=${index}`
        )
        .then(async (res) => {
          this.newsList = res.data.data.list;
        });
    },
    changeNewsImageIndex(index) {
      this.newsImageIndex = index;
      this.$refs.newsImgCarousel.setActiveItem(index);
    },
    changeNewsImgCarousel(index) {
      this.newsImageIndex = index;
    },
    toPath(path, id) {
      this.$router.push({ path, query: { websiteCategoryId: id } });
      window.scrollTo(0, 0);
    },
    async getNewsList() {
      //集团要闻
      await axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=10&websiteCategoryId=17`
        )
        .then(async (res) => {
          this.img_list1 = res.data.data.list.splice(0, 3);
          this.$nextTick(() => {
            this.swiperImg1 = new Swiper('.swiper-container1', {
              observer: true,  //开启动态检查器，监测swiper和slide
              observeParents: true,  //监测Swiper 的祖/父元素
              pagination: {
                el: '.swiper-pagination1',
              },
            });
            // 产业发展初始化
            const that = this
            this.container7 = new Swiper('.swiper-container7', {
              initialSlide: 2,
              loop: true,
              slidesPerView: 3,
              spaceBetween: 30,
              freeMode: true,
              observer: true,  //开启动态检查器，监测swiper和slide
              observeParents: true,  //监测Swiper 的祖/父元素
              on: {
                transitionEnd: function (swiper) {
                  swiper.slideTo(swiper.activeIndex, 1000, false)
                },
                click: function (swiper) {
                  if (swiper.activeIndex == 11) {
                    that.toHomePath(10, { id: 9 })
                  }
                  if (swiper.activeIndex == 13) {
                    that.toHomePath(2, { id: 5 })
                  }
                  if (swiper.activeIndex == 11) {
                    that.toHomePath(9, { id: 8 })
                  }
                }
              },
            });
            this.liudong(); //产业发展按钮自动流动
          })
        });
      await axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=10&websiteCategoryId=16`
        )
        .then(async (res) => {
          this.img_list2 = res.data.data.list.splice(0, 3);
          this.$nextTick(() => {
            this.swiperImg2 = new Swiper('.swiper-container2', {
              observer: true,  //开启动态检查器，监测swiper和slide
              observeParents: true,  //监测Swiper 的祖/父元素
              pagination: {
                el: '.swiper-pagination2',
              },
            });
          });
        });
      await axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=10&websiteCategoryId=15`
        )
        .then(async (res) => {
          this.img_list3 = res.data.data.list.splice(0, 3);
          this.$nextTick(() => {
            this.swiperImg3 = new Swiper('.swiper-container3', {
              observer: true,  //开启动态检查器，监测swiper和slide
              observeParents: true,  //监测Swiper 的祖/父元素
              pagination: {
                el: '.swiper-pagination3',
              },
            });
          });
        });

      await axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=10&websiteCategoryId=27`
        )
        .then(async (res) => {
          this.img_list4 = res.data.data.list.splice(0, 3);
          this.$nextTick(() => {
            this.swiperImg4 = new Swiper('.swiper-container4', {
              loop: true,
              observer: true,  //开启动态检查器，监测swiper和slide
              observeParents: true,  //监测Swiper 的祖/父元素
              pagination: {
                el: '.swiper-pagination4',
              },
            });
          });
        });
      await axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=10&websiteCategoryId=28`
        )
        .then(async (res) => {
          this.img_list5 = res.data.data.list.splice(0, 3);
          this.$nextTick(() => {
            this.swiperImg5 = new Swiper('.swiper-container5', {
              loop: true,
              observer: true,  //开启动态检查器，监测swiper和slide
              observeParents: true,  //监测Swiper 的祖/父元素
              pagination: {
                el: '.swiper-pagination5',
              },
            });
          });
        });
      await axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=10&websiteCategoryId=24`
        )
        .then(async (res) => {
          this.img_list6 = res.data.data.list.splice(0, 3);
          this.$nextTick(() => {
            this.swiperImg6 = new Swiper('.swiper-container6', {
              loop: true,
              observer: true,  //开启动态检查器，监测swiper和slide
              observeParents: true,  //监测Swiper 的祖/父元素
              pagination: {
                el: '.swiper-pagination6',
              },
            });
          });
        });
    },
    toHomePath(index, item) {
      if (index == 5) {
        window.open("https://sj.zoneyu.net/");
      } else if (index == 4) {
        window.open("https://www.zoneyu.cc/");
      } else if (index == 3) {
        window.open("https://www.zoneyu.com.cn/");
      } else {
        this.$router.push({ path: "/industrial_development", query: { id: item.id } });
      }
    },
    thereJunp(item, x) {
      this.$router.push({ path: '/press_center_DetailPage', query: { v: item.newsId, websiteCategoryId: x } });
    },
    liudong() {
      this.liudongInterval = setInterval(() => {
        this.container7.slideNext(1000, false)
      }, 8000);
    },
  },
  beforeDestroy() {
    clearInterval(this.liudongInterval)
    clearTimeout(this.imgSuffixTime)
  }
};
</script>

<style scoped lang="scss">
.home {
  min-width: 1240px;

  .home_title {
    width: 1240px;
    background: #e11515;
    color: #fff;
    padding: 0;
    height: 41px;
    line-height: 41px;

    .home_title_list {
      display: flex;
      width: 1240px;
      margin: 0 auto;

      .home_title_list_item {
        display: block;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 800;
        padding: 0;
        cursor: pointer;

        span {
          margin-left: 35px;
        }
      }
    }
  }

  .home_card {
    .home_card_item {
      width: 100%;
      height: 100%;
      background-size: cover !important;
    }
  }

  .home_intr {
    display: flex;

    .home_intr_bg {
      flex: 1;
      height: 65px;
      background: #34476e;
      text-align: end;

      img {
        margin: 25px 20px 0 0;
      }
    }

    .home_intr_left {
      flex: 3;
      line-height: 45px;
      font-size: 14px;
      font-weight: 600;
      overflow: hidden;

      .home_intr_left_top {
        height: 45px;
        color: #f2cfd1;
        background-color: #c51722;
        display: flex;

        .home_intr_left_top_content {
          animation: topAn 30s linear infinite;
          transform: translate(100vw, 0);
          white-space: nowrap;
        }
      }

      @keyframes topAn {
        0% {
          transform: translate(0, 0);
        }

        100% {
          transform: translate(-94vw, 0);
        }
      }

      .home_intr_left_bottom {
        height: 20px;
        color: #836105;
        background-color: #e9c259;

        .home_intr_left_bottom_content {
          animation: bottomAn 10s linear infinite;
          transform: translate(200px, 0);
          white-space: nowrap;
        }
      }

      @keyframes bottomAn {
        0% {
          transform: translate(100px, 0);
        }

        100% {
          transform: translate(-300px, 0);
        }
      }
    }
  }

  .home_news {
    display: flex;
    width: 1200px;
    margin: 50px auto;
    padding: 0 20px;

    .home_news_bg {
      width: 586px;
      position: relative;

      img {
        transform: scale(1.4);
      }

      .home_news_bg_bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        color: #fff;
        padding: 0 16px;
        font-size: 14px;
        font-weight: bold;
        width: calc(100% - 32px);
        height: 40px;
        line-height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #0000004d;

        .home_news_bg_bottom_title {
          cursor: pointer;
          width: 70%;
          white-space: nowrap;
          /* 防止文本换行 */
          overflow: hidden;
          /* 控制文本溢出时隐藏超出部分 */
          text-overflow: ellipsis;
          /* 当文本溢出时显示省略号 */
        }

        .home_news_bg_bottom_Index {
          float: left;
          color: #c51723;
          width: 20px;
          height: 18px;
          position: relative;
          line-height: 18px;
          border: 1px solid #c5172394;
          text-align: center;
          margin-right: 5px;
          cursor: pointer;
          background: #ffffff9a;
        }

        .home_news_bg_bottom_Index:hover {
          color: #fff;
          background: #c51723;
          border: 1px solid #c51723;
        }

        .active {
          color: #fff;
          background: #c51723;
          border: 1px solid #c51723;
        }
      }
    }

    .home_news_left {
      margin-left: 40px;
      width: calc(100% - 626px);

      .home_news_left_title {
        display: flex;
        width: 100%;
        border-bottom: 3px solid #ddd;

        .home_news_left_title_name {
          margin-left: 10px;
          padding: 0 25px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          border: 1px solid #ddd;
          background: #fff;
          color: #000;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          cursor: pointer;
          font-size: 18px;
          font-weight: 800;
        }

        .home_news_left_title_name:first-child {
          margin-left: 0;
        }

        .active {
          background: #ff0000;
          color: #fff;
          transition: all 0.3s ease-in-out 0s;
        }
      }

      .home_news_left_list {
        cursor: pointer;
        width: 100%;
        display: flex;
        line-height: 110px;
        height: 110px;
        border-bottom: 1px solid #ddd;
        justify-content: space-between;

        .home_news_left_list_title {
          width: 85%;
          font-size: 17px;
          font-weight: 800;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 当文本溢出时显示省略号 */
        }

        .home_news_left_list_day {
          font-size: 13px;
          color: #0000008c;
        }
      }
    }
  }

  .home_img {
    width: 1200px;
    margin: 50px auto;
    padding: 0 20px;
  }

  .home_all {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 50px;
    position: relative;
    padding: 0 20px;

    .home_all_item {
      width: 1200px;

      .home_all_item_index {
        cursor: pointer;
        position: relative;
      }
    }

    .home_all_module {
      display: flex;
      color: #cc0000;
      font-size: 18px;
      font-weight: 800;
      overflow: hidden;

      .swiper-container1,
      .swiper-container2,
      .swiper-container3,
      .swiper-container4,
      .swiper-container5,
      .swiper-container6 {
        width: 32%;
        margin: 0;
        margin-right: 2%;
      }

      .swiper-container3,
      .swiper-container6 {
        margin-right: 0;
      }

      .home_all_module_name {
        margin-bottom: 20px;
        width: 100%;
        margin-right: 2%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .home_all_module_name_more {
          font-size: 14px;
          color: #a68862;
          font-weight: 500;
          cursor: pointer;
        }
      }

      .home_all_module_img {
        cursor: pointer;
      }

      .home_all_content {
        margin-top: 10px;
        border: 1px solid #ddd;
        overflow: hidden;
        padding: 10px 15px;
        width: 100%;

        .home_all_content_item {
          cursor: pointer;
          color: #333;
          white-space: nowrap;
          overflow: hidden;
          font-weight: 500;
          text-overflow: ellipsis;
          font-size: 14px;
          line-height: 30px;
          position: relative;
          padding-left: 20px;
          text-align: left;
        }

        .home_all_content_item::before {
          content: "";
          background-color: #000;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 12px;
        }
      }

      .home_all_module_black {
        width: 100%;
        margin-right: 2%;
        height: 400px;
        overflow: hidden;

        .home_all_module_black_intro {
          width: 344px;
          height: 400px;
          background: rgba(0, 0, 0, 0.7);
          color: #fff;
          text-align: left;
          font-size: 14px;
          font-weight: 500;
          padding: 0 20px;
          margin-top: 280px;
          line-height: 20px;
          cursor: pointer;

          .home_all_module_black_intro_title {
            font-size: 32px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 50px;
            line-height: 60px;
            padding-top: 20px;
            text-align: center;

            .home_all_module_black_intro_title_red {
              background-color: red;
              height: 2px;
              width: 30px;
              margin: 0 auto;
              transition: all 1s ease-in-out 0s;
            }
          }

          .home_all_module_black_intro_content {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            line-height: 24px;
          }

          .home_all_module_black_intro_more {
            margin-top: 40px;
            text-align: center;
            color: #ffffffbb;
          }
        }


        @keyframes changeMargin {
          0% {
            margin-top: 230px;
          }

          100% {
            margin-top: 0;
          }
        }
      }

      .home_all_module_img:last-child,
      .home_all_module_name:last-child,
      .home_all_module_black:last-child {
        margin-right: 0;
      }
    }

    .home_all_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .home_animationS {
    .animationS {
      margin-top: 10px;
      width: 1240px;
      font-family: "MaShanZheng-Regular", sans-serif;
      color: #fff;
      font-size: 35px;
      line-height: 60px;
      text-align: center;
      position: relative;
      margin: 0 auto;
    }

    .animationS .first-show,
    .animationS .second-show {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1, 1);
      font-family: 楷体;
    }

    .animationS .third-show {
      text-align: center;
      // width: calc(100% - 40px);
      // height: 370px;
      font-size: 18px;
      line-height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1, 1);
      font-family: 楷体;
    }

    .animationS .second-show {
      transform: translate(-50%, -50%) scale(0, 0);
    }

    .animationS .first-show-title,
    .animationS .second-show-title {
      font-size: 35px;
    }

    .animationS .first-show-line,
    .animationS .second-show-line {
      height: 3px;
      background-color: #fff;
      width: 50px;
      margin: 50px auto;
    }

    .animationS .first-show {
      animation: Fshow 11s linear forwards;
      animation-iteration-count: infinite;
    }

    @keyframes Fshow {
      0% {
        transform: translate(-50%, -50%) scale(0, 0);
      }

      9% {
        transform: translate(-50%, -50%) scale(0, 0);
      }

      10% {
        transform: translate(-50%, -50%) scale(1, 1);
      }

      20% {
        transform: translate(-50%, -50%) scale(1, 1);
      }

      42% {
        transform: translate(-50%, -50%) scale(0, 0);
      }

      100% {
        transform: translate(-50%, -50%) scale(0, 0);
      }
    }

    .animationS .second-show {
      animation: Sshow 11s linear forwards;
      animation-iteration-count: infinite;
    }

    @keyframes Sshow {
      0% {
        transform: translate(-50%, -50%) scale(0, 0);
      }

      43% {
        transform: translate(-50%, -50%) scale(0, 0);
      }

      64% {
        transform: translate(-50%, -50%) scale(0.8);
      }

      80% {
        transform: translate(-50%, -50%) scale(0.8);
      }

      100% {
        transform: translate(-50%, -50%) scale(0, 0);
      }
    }
  }

  .home_ten {
    width: 100%;
    background: url("../../static/newImage/chanye2.png") no-repeat;
    background-size: cover;

    .home_box {
      margin: 0 auto 80px auto;

      .box {
        width: 100%;
      }

      .boxImg {
        width: 100%;
        text-align: center;
        overflow: hidden;
      }

      .centre {
        width: 1010px;
        height: 664px;
        margin: 0 auto;
        margin-bottom: 20px;
        display: flex;
        position: relative;
        flex-wrap: wrap;
        align-content: center;
      }

      .all-btn {
        display: flex;
        z-index: 99;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 40px;
      }

      .l-btn {
        height: 40px;
        box-shadow: 0px 2px 15px 0px rgba(197, 23, 34, 0.12);
        border-radius: 21px;
        font-size: 20px;
        font-weight: 500;
        color: #8c8f92;
        line-height: 40px;
        letter-spacing: 1px;
        cursor: pointer;
        padding: 0 21px;
      }

      .l-btn.active {
        background: #c51722;
        box-shadow: 0px 2px 15px 0px rgba(255, 0, 0, 0.23);
        color: #ffffff;
      }

      .centreSty {
        background-color: #e23127 !important;
        color: #fff !important;
      }

      .boxText {
        width: 1230px;
        height: 300px;
        background: #f5f5f7;
        margin: 0 auto;
      }

      .boxcentre {
        display: flex;
        padding: 25px;
        justify-content: flex-start;
      }

      .boxcentreRImg {
        /* background-color: red; */
        width: 300px;
        height: 400px;
      }

      .boxcentreR {
        overflow: hidden;
        height: 244px;
        width: 500px;
      }

      .boxcentreL {
        width: 800px;
        font-size: 16px;
        font-weight: 400;
        color: #5b5d5f;
        line-height: 23px;
        letter-spacing: 1px;
        margin-left: 30px;
        text-align: left;
        position: relative;
      }
    }

    .home_btn {
      margin: 80px auto;
      width: 1240px;
      position: relative;

      .home_btn_left,
      .home_btn_right {
        bottom: 350px;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        height: 70px;
        width: 70px;
        font-size: 30px;
        font-weight: bold;
        line-height: 70px;
        cursor: pointer;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        border-radius: 50%;
        background-color: #040404a1;
        color: #fff;
        position: absolute;
        z-index: 10;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
      }

      .home_btn_left {
        left: 30px;
      }

      .home_btn_right {
        left: 1100px;
      }
    }
  }
}

::v-deep .swiper-container7 {
  .swiper-slide-next {
    .active {
      animation: changeMargin 1s linear forwards;

      .home_all_module_black_intro_title_red {
        width: 60px !important;
      }

      .home_all_module_black_intro_title {
        padding-top: 60px;
      }
    }
  }
}


.swiper-container {
  --swiper-theme-color: #e11515;
}

::v-deep .el-carousel__arrow {
  transform: scale(1.7);
  background-color: #1f2d3d80;
}

::v-deep .el-carousel__arrow--right {
  right: 50px;
}

::v-deep .el-carousel__arrow--left {
  left: 50px;
}

::-webkit-scrollbar {
  width: 0.5em;
  /* 设置滚动条的宽度 */
}
</style>
